<div class="container" id="top-navbar">
  <div class="logo-img-container">
    <img alt="Guesic" src="./assets/logo/logo-text-inverted.png"/>
  </div>
  <div class="profile-icon-container">
    <p-menu #menu [model]="Items" [popup]="true">
      <button class="btn-default">Logout</button>
    </p-menu>
    <p *ngIf="User?.email" class="profile-character" (click)="menu.toggle($event)">
      {{ getFirstAlphabeticalCharacter(User.email) }}
    </p>
  </div>
</div>

