import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {SpotifyService} from "../services/spotify.service";
import {FirebaseService} from "../services/firebase.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private spotifyService: SpotifyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Token might have expired, refresh it
          return this.spotifyService.getRefreshToken().pipe(
            switchMap(() => {
              // Clone the original request with the new access token
              const clonedRequest = request.clone({
                setHeaders: {
                  Authorization: `Bearer ${localStorage.getItem('spotify_access_token')}`
                }
              });
              // Retry the original request with the new token
              return next.handle(clonedRequest);
            })
          );
        }
        // If it's not a 401 error, just forward the error
        return throwError(error);
      })
    );
  }
}
