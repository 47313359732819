import { Injectable } from '@angular/core';
import {BehaviorSubject, debounceTime, fromEvent, Observable, startWith} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectionService {

  private _isMobile = new BehaviorSubject<boolean>(this.isMobile());
  private _isDesktop = new BehaviorSubject<boolean>(this.isDesktop());
  private _isSmallScreen = new BehaviorSubject<boolean>(this.isSmallScreen());

  constructor() {
    this.setupScreenSizeListener();
  }

  public get IsMobile$(): Observable<boolean> {
    return this._isMobile.asObservable();
  }

  public get IsDesktop$(): Observable<boolean> {
    return this._isDesktop.asObservable();
  }

  public get IsSmallScreen$(): Observable<boolean> {
    return this._isSmallScreen.asObservable();
  }

  public DeviceCheck() {
    this._isMobile.next(this.isMobile());
    this._isDesktop.next(this.isDesktop());
    this._isSmallScreen.next(this.isSmallScreen());
  }

  private isMobile(): boolean {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  }

  private isDesktop(): boolean {
    // You might want to adjust this logic based on your requirements
    return !this.isMobile();
  }

  private isSmallScreen(): boolean {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 768; // Example breakpoint, adjust as needed
  }

  private setupScreenSizeListener() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(100), // Debounce time can be adjusted based on how responsive you want it to be
        startWith(null) // Trigger initial check
      )
      .subscribe(() => {
        this._isSmallScreen.next(this.isSmallScreen());
      });
  }

}
