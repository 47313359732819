import {inject, Injectable} from '@angular/core';
import {CanActivate, CanActivateFn} from '@angular/router';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {catchError, map, take} from 'rxjs/operators';
import {AuthService} from "../services/auth.service";
import {Observable, of, switchMap} from "rxjs";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireDatabase} from "@angular/fire/compat/database";

export const canActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.CheckLogin().pipe(
    catchError(() => router.navigateByUrl('/login'))
  );
};

@Injectable({
  providedIn: 'root'
})
export class AdminAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(): Observable<boolean> {
    return this.authService.user$.pipe(
      take(1),
      switchMap(user => {
        if (!user) {
          this.router.navigate(['/login']);
          return of(false);
        }
        return this.authService.getUserRole(user.uid).pipe(
          map(role => {
            if (role === 'admin') {
              return true;
            } else {
              this.router.navigate(['/']); // or any other fallback route
              return false;
            }
          })
        );
      })
    );
  }
}
