import { Routes } from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {HomeComponent} from "./components/home/home.component";
import {AdminAuthGuard, canActivate} from "./helpers/AuthGuard";
import {AdminComponent} from "./components/admin/admin.component";
import {RegisterComponent} from "./components/register/register.component";

export const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [canActivate] },
  { path: 'login', component: LoginComponent},
  { path: 'register', component: RegisterComponent},
  { path: 'admin', component: AdminComponent, canActivate: [AdminAuthGuard]},
  { path: '**', redirectTo: ''},
];
