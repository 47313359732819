import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, of, switchMap, tap, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastService} from "./toast.service";
import {SpotifyDevice} from "../models/ISpotifyDevice";

@Injectable({
  providedIn: 'root',
})
export class SpotifyService {
  private baseUrl: string = 'https://api.spotify.com/v1';
  private _shouldAuthenticate = new BehaviorSubject<boolean>(false);
  private _shouldSelectDevice = new BehaviorSubject<boolean>(false);
  private _devices = new BehaviorSubject<SpotifyDevice[]>([]);
  private clientId: string = "08854cad33744f36a2900a7331646048";

  constructor(private http: HttpClient, private _toastService: ToastService) {
  }

  public get ShouldAuthenticate$(): Observable<boolean> {
    return this._shouldAuthenticate.asObservable();
  }

  public set ShouldAuthenticate$(bool: boolean) {
    this._shouldAuthenticate.next(bool);
  }

  public get ShouldSelectDevice$(): Observable<boolean> {
    return this._shouldSelectDevice.asObservable();
  }

  public set ShouldSelectDevice$(bool: boolean) {
    this._shouldSelectDevice.next(bool);
  }

  public get Devices$(): Observable<SpotifyDevice[]> {
    return this._devices.asObservable();
  }

  public set Devices$(device: SpotifyDevice[]) {
    this._devices.next(device);
  }

  public GetTrackUri(artist: string, title: string, year: number): Observable<string | null> {
    const token = localStorage.getItem('spotify_access_token');
    if (!token) {
      return throwError(() => new Error('Spotify access token not found in localStorage'));
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });

    const queries = [
      encodeURIComponent(`track:${title} artist:${artist} year:${year}`),
      encodeURIComponent(`track:${title} artist:${artist}`),
      encodeURIComponent(`track:${title}`)
    ];
    return this.tryFetchTrackUri(queries, 0, headers);
  }

  private tryFetchTrackUri(queries: string[], index: number, headers: HttpHeaders): Observable<string | null> {
    if (index >= queries.length) {
      this._toastService.showError('Track not found');
      this._shouldAuthenticate.next(true);
      return of(null);
    }

    const url = `${this.baseUrl}/search?q=${queries[index]}&type=track&limit=1`;

    return this.http.get<any>(url, { headers }).pipe(
      switchMap(response => {
        if (response.tracks && response.tracks.items.length > 0) {
          const trackUri = response.tracks.items[0].uri;
          return of(trackUri);
        } else {
          return this.tryFetchTrackUri(queries, index + 1, headers);
        }
      }),
      catchError(error => {
        console.error(`Error fetching track URI on try ${index + 1}:`, error);
        // Try the next query in case of an error
        return this.tryFetchTrackUri(queries, index + 1, headers);
      })
    );
  }

  public GetActiveDevices(): Observable<any> {
    const token = localStorage.getItem('spotify_access_token');
    if (!token) {
      throw new Error('Spotify access token not found in localStorage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });

    const url: string = `https://api.spotify.com/v1/me/player/devices`;
    return this.http.get<any>(url, {headers}).pipe(
      catchError((error) => {
        return throwError(() => new Error('Failed to fetch devices.'));
      })
    );
  }

  public GetPlaybackState(): Observable<any> {
    const token = localStorage.getItem('spotify_access_token');
    if (!token) {
      throw new Error('Spotify access token not found in localStorage');
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`,
    });

    const url = `${this.baseUrl}/me/player`;
    return this.http.get(url, {headers});
  }

  public StartPlayback(spotifyUri: string, position: number = 0, deviceId: string): void {
    const accessToken = localStorage.getItem('spotify_access_token');
    if (!accessToken) {
      console.error('Spotify access token not found');
      return;
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    });

    const body = spotifyUri?.startsWith('spotify:track:') ?
      {uris: [spotifyUri], position_ms: 0} :
      {context_uri: spotifyUri, offset: {position: position}, position_ms: 0};

    this.http.put(`https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`, body, {headers}).subscribe({
      next: (response) => {
      },
      error: (error) => console.error('Error starting playback', error.status),
    });
  }

  public PausePlayback(deviceId?: string): Observable<any> {
    const accessToken = localStorage.getItem('spotify_access_token');
    if (!accessToken) {
      return throwError(() => new Error('Authentication required'));
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`,
    });

    let url = `${this.baseUrl}/me/player/pause`;
    if (deviceId) {
      url += `?device_id=${deviceId}`;
    }

    return this.http.put(url, {}, {headers}).pipe(
      catchError(error => {
        return throwError(() => new Error('Error pausing playback.'));
      })
    );
  }

  public ResumePlayback(deviceId?: string): Observable<any> {
    const accessToken = localStorage.getItem('spotify_access_token');
    if (!accessToken) {
      return throwError(() => new Error('Authentication required'));
    }

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${accessToken}`,
    });

    let url = `${this.baseUrl}/me/player/play`;
    if (deviceId) {
      url += `?device_id=${deviceId}`;
    }

    return this.http.put(url, {}, {headers}).pipe(
      catchError(error => {
        return throwError(() => new Error('Error resuming playback.'));
      })
    );
  }

  public getRefreshToken(): Observable<any> {
    const tokenUrl = 'https://accounts.spotify.com/api/token';

    const refreshToken = localStorage.getItem('spotify_refresh_token');
    if (!refreshToken) {
      // Handle the case where there is no refresh token in localStorage
      return throwError(() => new Error('Refresh token not found'));
    }

    const body = new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      client_id: this.clientId
    });

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.http.post<any>(tokenUrl, body.toString(), {headers}).pipe(
      tap(response => {
        localStorage.setItem('spotify_access_token', response.access_token);
        // Only set the refresh token if you receive a new one
        if (response.refresh_token) {
          localStorage.setItem('spotify_refresh_token', response.refresh_token);
        }
      }), catchError(error => {
        this.promptReAuthentication();
        return throwError(() => error);
      })
    );
  }

  private promptReAuthentication(): void {
    this._shouldAuthenticate.next(true);
  }
}
