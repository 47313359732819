import {AfterViewInit, Component, HostListener, NgZone, OnDestroy} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AsyncPipe, JsonPipe, NgForOf, NgIf} from "@angular/common";
import QrScanner from "qr-scanner";
import {DeviceDetectionService} from "../../services/device-detection.service";
import {ComponentBase} from "../../models/component-base";
import {SwipeDirective} from "../../directive/swipe.directive";
import {FirebaseService} from "../../services/firebase.service";
import {SpotifyService} from "../../services/spotify.service";
import {ISong} from "../../models/ISong";
import {DialogModule} from "primeng/dialog";
import {SpotifyDevice} from "../../models/ISpotifyDevice";
import {interval, Subscription, switchMap} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ToastService} from "../../services/toast.service";

@Component({
  selector: 'scanner',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, JsonPipe, AsyncPipe, NgIf, NgForOf, SwipeDirective, DialogModule],
  templateUrl: './qrscanner.component.html',
  styleUrls: ['./qrscanner.component.scss'],
  animations: [
  trigger('fadeSonar', [
    state('visible', style({
      opacity: 1,
    })),
    state('hidden', style({
      opacity: 0,
    })),
    transition('visible => hidden', [
      animate('0.5s ease-out')
    ]),
    transition('hidden => visible', [
      animate('0.5s ease-in')
    ]),
  ])
]
})
export class QrScannerComponent extends ComponentBase implements AfterViewInit {

  public QrScanner: any;
  public CamList: any;
  public QrResult: string = "None";
  public PausePlayText: string = "Pause";
  public StartStopText: string = "Start";

  public _isScanning: boolean = false;
  public IsMobile: boolean = false;
  public IsDesktop: boolean = false;
  public IsSmallScreen: boolean = false;
  public ShouldSelectDevice: boolean = false;
  public IsSongPlaying: boolean = false;
  public Devices: SpotifyDevice[] = [];

  private ignorePollingUpdate: boolean = false;
  private _chosenDevice: SpotifyDevice | null = null;
  private playbackStateSubscription: Subscription | null = null;
  private spotifyUri: string | null = '';
  private isInitialized: boolean = false;
  private NL_GUILTY_PLEASURE_HITSTER_GAME_URL: string = "www.hitstergame.com/nl/aaaa0006"

  constructor(private zone: NgZone, private _deviceService: DeviceDetectionService, private _firebaseService: FirebaseService,
              private _spotifyService: SpotifyService, private _toastService: ToastService) {super()}

  protected override OnInit(): void {
    if (!this.IsScanning && this.ChosenDevice) {
      this.startPolling();
    }

    const subscription = this._deviceService.IsMobile$.subscribe(bool => {
      this.IsMobile = bool;
    });

    const subscription1 = this._deviceService.IsDesktop$.subscribe(bool => {
      this.IsDesktop = bool;
      if (bool && this.isInitialized) {
        this.cameraSetup();
      }
    });

    const subscription2 = this._deviceService.IsSmallScreen$.subscribe(bool => {
      this.IsSmallScreen = bool;
    });

    const subscription3 = this._spotifyService.ShouldSelectDevice$.subscribe(bool => {
      this.ShouldSelectDevice = bool;
    });

    const subscription4 = this._spotifyService.Devices$.subscribe(devices => {
      this.Devices = devices;
    });

    this.Subscriptions.add(subscription);
    this.Subscriptions.add(subscription1);
    this.Subscriptions.add(subscription2);
    this.Subscriptions.add(subscription3);
    this.Subscriptions.add(subscription4);
  }

  override ngOnDestroy() {
    if (this.playbackStateSubscription) {
      this.playbackStateSubscription.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.adjustQrScannerHeight();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.adjustQrScannerHeight()
    }, 200)

    const video = document.getElementById('qr-video') as HTMLVideoElement;

    this.QrScanner = new QrScanner(video, result => this.resultFromScan(result), {
      onDecodeError: error => {},
      highlightScanRegion: true,
      highlightCodeOutline: true
    });

    this.QrScanner.setInversionMode("both");
    this.cameraSetup();
    this.isInitialized = true;
  }

  public OnSwipe(): void {
    this.StartScanning();
  }

  public ToggleScanning() {
    this.IsScanning = !this.IsScanning;
    if (this.IsScanning) {
      this.StartStopText = "Stop";
      this.StartScanning();
    } else {
      this.StartStopText = "Start";
      this.StopScanning();
    }
  }

  public StartScanning(): void {
    this.IsScanning = true;
    this.QrScanner.start();
  }

  public StopScanning(): void {
    this.IsScanning = false;
    this.QrScanner.stop();
  }

  public SelectDevice(device: SpotifyDevice) {
    this.ChosenDevice = device;
    this._spotifyService.ShouldSelectDevice$ = false;
    this.playSong(this.spotifyUri, device.id);
    this.IsSongPlaying = true;
  }

  public FetchDevices() {
    this._spotifyService.GetActiveDevices().subscribe(response => {
      if (response && response.devices) {
        this._spotifyService.Devices$ = response.devices;
      } else {
        this._spotifyService.Devices$ = [];
      }
      this._spotifyService.ShouldSelectDevice$ = true;
    });
  }

  public ToggleSongPause() {
    this.IsSongPlaying = !this.IsSongPlaying;
    this.ignorePollingUpdate = true;

    setTimeout(() => this.ignorePollingUpdate = false, 2500);

    if (!this.IsSongPlaying) {
      // If toggled to not playing, pause the song
      this.PausePlayText = "Play";
      this._spotifyService.PausePlayback(this.ChosenDevice?.id).subscribe({
        next: () => {},
        error: (err) => {
          console.error('Error pausing playback:', err);
          // If there was an error, revert the toggle to reflect the correct state
          this.IsSongPlaying = !this.IsSongPlaying;
          this.PausePlayText = "Pause";
          this.ignorePollingUpdate = false;
        }
      });
    } else {
      // If toggled to playing, play the song
      this.PausePlayText = "Pause";
      this._spotifyService.ResumePlayback(this.ChosenDevice?.id).subscribe({
        next: () => {},
        error: (err) => {
          console.error('Error resuming playback:', err);
          // If there was an error, revert the toggle to reflect the correct state
          this.IsSongPlaying = !this.IsSongPlaying;
          this.PausePlayText = "Play";
          this.ignorePollingUpdate = false;
        }
      });
    }
  }

  private cameraSetup() {
    if (this.IsDesktop) {
    this.CamList = document.getElementById('cam-list');
    const defaultOption = document.createElement('option');
    defaultOption.value = 'none';
    defaultOption.text = 'No Camera Selected';
    defaultOption.selected = true;
    this.CamList.add(defaultOption);

    this.CamList.addEventListener('change', (event: { target: { value: any; }; }) => {
      this.QrScanner.setCamera(event.target.value);
    });

    QrScanner.listCameras(true).then(cameras => cameras.forEach(camera => {
      const option = document.createElement('option');
      option.value = camera.id;
      option.text = camera.label;
      this.CamList.add(option);
    }));
    }
  }

  private resultFromScan(result: QrScanner.ScanResult) {
    this.zone.run(() => {
      this.QrResult = result.data.toString();
      if (this.QrResult.startsWith(this.NL_GUILTY_PLEASURE_HITSTER_GAME_URL)) {
        let {gameType, songId} = this.extractParts(this.QrResult);
        this.getSongData(gameType, songId);
        this.StopScanning();
      } else {
        this._toastService.showError("This QR is not supported.");
      }
    });
  }

  private getSongData(gameType: string | null, songId: string | null) {
    this._firebaseService.GetSong(gameType, songId).subscribe({
      next: (data) => {
        let song: ISong = {
          id: Number(songId),
          title: data.title,
          artist: data.artist,
          year: data.year,
          colorCode: data.colorCode,
        }
        this.searchTrack(song.artist, song.title, song.year);
      },
      error: (error) => {
        console.error('Error fetching song data:', error);
      }
    });
  }

  private extractParts(url: string) {
    const regex = /www\.hitstergame\.com\/nl\/(?:([^\/]+)\/)?([^\/]+)/;
    const matches = url.match(regex);

    let gameType = null, songId = null;

    if (matches) {
      gameType = matches[1] || null;
      songId = matches[2];
    }
    return {gameType, songId};
  }

  private searchTrack(artist: string, title: string, year: number) {
    this._spotifyService.GetTrackUri(artist, title, year).subscribe(uri => {
      this.spotifyUri = uri;
      if (this.spotifyUri && !this.ChosenDevice) {
        this._spotifyService.GetActiveDevices().subscribe(response => {
          if (response && response.devices) {
            this._spotifyService.Devices$ = response.devices;
          } else {
            this._spotifyService.Devices$ = [];
          }
          this._spotifyService.ShouldSelectDevice$ = true;
        });
      }
      if (this.ChosenDevice) {
        this.playSong(this.spotifyUri, this.ChosenDevice.id);
        this.IsSongPlaying = true;
      }
    }, error => {
      this._spotifyService.ShouldAuthenticate$ = true;
      console.error('Error fetching track URI:', error);
    });
  }

  private playSong(spotifyUri: string | null, device_id: string): void {
    if (spotifyUri && device_id) {
      this._spotifyService.StartPlayback(spotifyUri, 0, device_id);
    }
  }

  private adjustQrScannerHeight() {
    const navbar = document.getElementById('top-navbar');
    const qrScanner = document.getElementById('qr-scanner');

    if (navbar && qrScanner) {
      const navbarHeight = navbar.offsetHeight;
      const windowHeight = window.innerHeight;
      const qrScannerHeight = windowHeight - navbarHeight;
      qrScanner.style.height = `${qrScannerHeight}px`;
    }
  }

  private startPolling() {
    this.playbackStateSubscription = interval(2000).pipe(
      switchMap(() => this._spotifyService.GetPlaybackState()),
    ).subscribe({
      next: (playbackState) => {
        if (!this.ignorePollingUpdate) {
          this.IsSongPlaying = playbackState && playbackState.is_playing;
        }
      },
      error: (error) => {
        this.playbackStateSubscription?.unsubscribe();
      }
    });
  }

  set IsScanning(value: boolean) {
    this._isScanning = value;
    this.checkPollingConditions();
  }

  get IsScanning(): boolean {
    return this._isScanning;
  }

  set ChosenDevice(value: SpotifyDevice | null) {
    this._chosenDevice = value;
    this.checkPollingConditions();
  }

  get ChosenDevice(): SpotifyDevice | null {
    return this._chosenDevice;
  }

  private checkPollingConditions() {
    if (!this.IsScanning && this.ChosenDevice && !this.playbackStateSubscription) {
      this.startPolling();
    } else if ((this.IsScanning || !this.ChosenDevice) && this.playbackStateSubscription) {
      this.playbackStateSubscription.unsubscribe();
      this.playbackStateSubscription = null;
    }
  }

}
