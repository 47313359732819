import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {QrScannerComponent} from "../qrscanner/qrscanner.component";
import {NavbarComponent} from "../navbar/navbar.component";
import {ComponentBase} from "../../models/component-base";
import {DeviceDetectionService} from "../../services/device-detection.service";
import {SpotifyComponent} from "../spotify/spotify.component";
import {NgIf} from "@angular/common";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {SpotifyService} from "../../services/spotify.service";
import {ToastModule} from "primeng/toast";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'home',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, QrScannerComponent, NavbarComponent, SpotifyComponent, NgIf, ToastModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends ComponentBase {

  public IsMobile: boolean = false;
  public IsDesktop: boolean = false;
  public IsSmallScreen: boolean = false;
  public IsConnectedToSpotify: boolean = false;
  public ShouldAuthenticateAgain: boolean = false;

  constructor(private _deviceService: DeviceDetectionService, private _route: ActivatedRoute,
              private _authService: AuthService, private _router: Router, private _spotifyService: SpotifyService) {
    super()
  }

  protected override OnInit(): void {
    const subscription = this._deviceService.IsMobile$.subscribe(bool => {
      this.IsMobile = bool;
    });

    const subscription1 = this._deviceService.IsDesktop$.subscribe(bool => {
      this.IsDesktop = bool;
    });

    const subscription2 = this._deviceService.IsSmallScreen$.subscribe(bool => {
      this.IsSmallScreen = bool;
    });

    const subscription3 = this._spotifyService.ShouldAuthenticate$.subscribe(bool => {
      this.ShouldAuthenticateAgain = bool;
    });

    this.Subscriptions.add(subscription);
    this.Subscriptions.add(subscription1);
    this.Subscriptions.add(subscription2);
    this.Subscriptions.add(subscription3);

    if (localStorage.getItem('spotify_access_token')) {
      this.IsConnectedToSpotify = true;
    }

    this._route.queryParams.subscribe(params => {
      const code = params['code'];

      if (!code) {
        return;
      }

      this._router.navigate(['.'], {relativeTo: this._route, queryParams: {}}).then(r => {
        this._authService.ExchangeCodeForToken(code).subscribe(response => {
          localStorage.setItem('spotify_access_token', response.access_token);
          localStorage.setItem('spotify_refresh_token', response.refresh_token);
          this.IsConnectedToSpotify = true;
          this._spotifyService.ShouldAuthenticate$ = false;
        }, error => {
          console.error('Error fetching access token', error);
        });
      });
    });
  }
}
