<div class="container">
  <div class="top-navbar">
    <div class="btn-home-container">
      <div class="back-icon-container" (click)="GoToHome()">
        <i class="pi pi-arrow-left"></i>
      </div>
    </div>
    <div class="center-container">
      <div class="choose-file-container">
        <input type="file" id="actual-btn" hidden (change)="OnFileChange($event)" #fileInput accept=".xlsx, .xls"/>
        <label for="actual-btn">Choose File</label>
        <span id="file-chosen">{{ FileName }}</span>
      </div>
    </div>
  </div>
  <div class="body-container">
    <mat-sidenav-container class="mat-sidenav-container">
      <mat-sidenav #sidenav mode="side" [(opened)]="isSidebarVisible" class="sidenav-container">
        <div class="color-picker-container">
          <div class="header-and-stroke">
            <p>Color gradient</p>
          </div>
          <div class="gradient-content-container">
            <div class="input-wrapper" *ngFor="let color of colorList; let i = index; trackBy: identify">
              <input readonly [(colorPicker)]="colorList[i]" [style.background]="color"/>
              <i class="pi pi-times" (click)="removeColor(i)"></i>
            </div>
            <div class="add-btn-container" (click)="addNewColor()">
              <i class="pi pi-plus"></i>
            </div>
          </div>
        </div>
        <div class="role-manage-container">
          <div class="header-and-stroke">
            <p>User permissions</p>
          </div>
          <div class="email-input-container">
            <input (focusout)="onSearchUser()" [(ngModel)]="email" class="email-input" type="email"
                   placeholder="Email"/>
            <p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{'height': '6px'}"></p-progressBar>
          </div>
          <div class="dropdown-container" *ngIf="selectedRole">
            <select class="select-role" [(ngModel)]="selectedRole" (ngModelChange)="onRoleChange($event)">
              <option *ngFor="let role of roles" value="{{role}}">{{ role }}</option>
            </select>
          </div>

          <div class="btn-execute-container" *ngIf="userRole != selectedRole">
            <button class="btn-action" (click)="updateUserRole()">Update Role</button>
          </div>

        </div>
      </mat-sidenav>
      <mat-sidenav-content class="sidenav-content-container">
        <div class="sidenav-opener-container">
          <div class="sidenav-opener" (click)="sidenav.toggle()">
            <i class="pi" [ngClass]="isSidebarVisible ? 'pi-angle-left' : 'pi-angle-right'"></i>
          </div>
        </div>
        <div class="cards-container">
          <div class="card-container" *ngFor="let song of SongsFromFile; let i = index"
               [ngStyle]="{'background-color': GetBackgroundColor(i)}">
            <div class="artist-text">{{ song.artist }}</div>
            <div class="year-text">{{ song.year }}</div>
            <div class="title-text">{{ song.title }}</div>
            <div class="id-text">{{ song.id }}</div>
          </div>
        </div>
        <div class="dialog-container" *ngIf="SongsFromFile.length > 0">
<!--        <div class="dialog-container">-->
          <p-confirmDialog></p-confirmDialog>
          <div class="dialog-btn-container">
            <button class="btn-error" (click)="confirmRemove($event)"><i class="pi pi-trash"></i>Remove</button>
            <button class="btn-succes" (click)="confirmUpload($event)"><i class="pi pi-cloud-upload"></i>Upload</button>
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>

