import {ApplicationConfig, importProvidersFrom} from '@angular/core';
import {provideRouter} from '@angular/router';
import {routes} from './app.routes';
import {provideFirebaseApp} from "@angular/fire/app";
import {initializeApp} from "firebase/app";
import {environment} from "../environments/environment";
import {getAuth, provideAuth} from "@angular/fire/auth";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {getStorage, provideStorage} from "@angular/fire/storage";
import {provideAnimations} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule,} from "@angular/common/http";
import {AuthInterceptor} from "./interceptors/auth.interceptor";
import {getDatabase, provideDatabase} from "@angular/fire/database";
import {MessageService} from "primeng/api";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes),
    MessageService,
    provideAnimations(),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    importProvidersFrom([
      provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
      provideAuth(() => getAuth()),
      provideFirestore(() => getFirestore()),
      provideStorage(() => getStorage()),
      provideDatabase(() => getDatabase()),
      HttpClientModule,
    ]), provideAnimationsAsync()
  ]
};
