import {Component, inject} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ComponentBase} from "../../models/component-base";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'register',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './register.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class RegisterComponent extends ComponentBase {

  public Email: string = "";
  public Password: string = "";
  public ErrorMessage: string = "";

  constructor(private _authService: AuthService) { super() }

  protected override OnInit(): void {
    const subscription = this._authService.GetMessage$.subscribe(message => {
      if (message && message != this.ErrorMessage) {
        this.ErrorMessage = message;
      }
    });
    this.Subscriptions.add(subscription);
  }

  public async Register() {
    await this._authService.RegisterAndSetUser(this.Email, this.Password);
  }

}
