import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {LoginComponent} from "./components/login/login.component";
import {DeviceDetectionService} from "./services/device-detection.service";
import {SpotifyService} from "./services/spotify.service";
import {ToastModule} from "primeng/toast";
import {ToastService} from "./services/toast.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoginComponent, ToastModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {

  constructor(private deviceService: DeviceDetectionService, private _spotifyService: SpotifyService, private _toastService: ToastService) {}

  ngOnInit() {
    this.deviceService.DeviceCheck();
    this._spotifyService.GetTrackUri("Boef", "Goudmijntje", 2023).subscribe(uri => {})
  }
}
