import { OnDestroy, OnInit, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export class ComponentBase implements OnDestroy, OnInit {

    public Subscriptions = new Subscription();

    protected OnInit(): void { }

    protected OnDestroy(): void { }

    public ngOnInit(): void {
        this.OnInit();
    }

    public ngOnDestroy(): void {
        this.Subscriptions.unsubscribe();
        this.OnDestroy();
    }

}