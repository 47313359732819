<div class="container" id="qr-scanner">
  <div class="border">
    <div *ngIf="!IsMobile" class="choose-camera-container">
      <div>
        <select id="cam-list" class="select-camera"></select>
        <div class="pc-btn-container">
          <button class="btn-default" (click)="ToggleScanning()">{{StartStopText}}</button>
        </div>
      </div>
    </div>

    <div class="camera-container" id="video-container" appSwipe (swipeLeft)="OnSwipe()" (swipeRight)="OnSwipe()">
      <video class="camera-inheritor" id="qr-video"></video>
    </div>

    <div *ngIf="IsMobile && !IsScanning" >
      <div class="pause-container" *ngIf="ChosenDevice">
        <div class="btn" [class.active]="IsSongPlaying" onclick="this.classList.toggle('active')" (click)="ToggleSongPause()">
          <div class="background" x="0" y="0" width="200" height="200"></div>
          <div class="icon" width="200" height="200">
            <div class="part left" x="0" y="0" width="200" height="200" fill="#fff"></div>
            <div class="part right" x="0" y="0" width="200" height="200" fill="#fff"></div>
          </div>
          <div class="pointer"></div>
          <div [@fadeSonar]="IsSongPlaying ? 'visible' : 'hidden'">
            <div class="sonar-wave sonar-wave1"></div>
            <div class="sonar-wave sonar-wave2"></div>
            <div class="sonar-wave sonar-wave3"></div>
            <div class="sonar-wave sonar-wave4"></div>
          </div>
        </div>
      </div>
      <div class="arrow-container">
        <i class="pi pi-angle-double-left"></i>
        <p style="padding: 8px">Swipe To Scan</p>
        <i class="pi pi-angle-double-right"></i>
      </div>
    </div>

    <div *ngIf="!IsMobile && !IsScanning">
<!--    <div *ngIf="!IsScanning">-->
      <div class="pause-container" *ngIf="ChosenDevice">
<!--      <div class="pause-container">-->
        <div class="btn" [class.active]="IsSongPlaying" onclick="this.classList.toggle('active')" (click)="ToggleSongPause()">
          <div class="background" x="0" y="0" width="200" height="200"></div>
          <div class="icon" width="200" height="200">
            <div class="part left" x="0" y="0" width="200" height="200" fill="#fff"></div>
            <div class="part right" x="0" y="0" width="200" height="200" fill="#fff"></div>
          </div>
          <div class="pointer"></div>
          <div [@fadeSonar]="IsSongPlaying ? 'visible' : 'hidden'">
            <div class="sonar-wave sonar-wave1"></div>
            <div class="sonar-wave sonar-wave2"></div>
            <div class="sonar-wave sonar-wave3"></div>
            <div class="sonar-wave sonar-wave4"></div>
          </div>
        </div>
      </div>
    </div>

    <p-dialog header="Select a Device" [(visible)]="ShouldSelectDevice">
      <div *ngIf="Devices && Devices.length > 0; else noDevices">
        <ul>
          <li *ngFor="let device of Devices" (click)="SelectDevice(device)" class="select-device">
            {{ device.name }} ({{ device.type }})
          </li>
        </ul>
      </div>
      <ng-template #noDevices>
        <p>No devices available. Please open Spotify on the web.</p>
      </ng-template>

      <div class="refresh-container">
        <button class="btn-default" (click)="FetchDevices()">
          <i class="pi pi-refresh refresh-icon"></i>Refresh Devices
        </button>
      </div>
    </p-dialog>

  </div>
</div>



