import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'spotify',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule],
  templateUrl: './spotify.component.html',
  styleUrls: ['./spotify.component.scss'],
})
export class SpotifyComponent {

  constructor(private _authService: AuthService) {}

  public async AuthorizeToSpotify() {
    const codeVerifier = this._authService.GenerateRandomString(128);
    const codeChallenge = await this._authService.GenerateCodeChallenge(codeVerifier);
    const clientId = this._authService.ClientId;

    const isLocalhost = window.location.hostname === 'localhost';
    const redirectUri = isLocalhost ? 'http://localhost:4200/' : environment.redirectUri;

    const scope = 'user-modify-playback-state user-read-playback-state';
    const authUrl = new URL("https://accounts.spotify.com/authorize");
    const params = {
      response_type: 'code',
      client_id: clientId,
      scope: scope,
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      redirect_uri: redirectUri,
    };

    authUrl.search = new URLSearchParams(params).toString();
    window.localStorage.setItem('code_verifier', codeVerifier);
    window.location.href = authUrl.toString();
  }

}
