import {Component} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AsyncPipe, JsonPipe, NgIf} from "@angular/common";
import {QrScannerComponent} from "../qrscanner/qrscanner.component";
import {AuthService} from "../../services/auth.service";
import {MenuModule} from "primeng/menu";
import {ButtonModule} from "primeng/button";
import {MenuItem, PrimeIcons} from "primeng/api";
import {User} from "@angular/fire/auth";
import {ComponentBase} from "../../models/component-base";
import {SpotifyService} from "../../services/spotify.service";

@Component({
  selector: 'navbar',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, JsonPipe, AsyncPipe, NgIf, QrScannerComponent, MenuModule, ButtonModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent extends ComponentBase {

  public Icons: string[] = ['bear.png', 'cat.png', 'giraffe.png', 'koala.png', 'lion.png', 'panda.png', 'puffer-fish.png', 'rabbit.png', 'sea-lion.png', 'sloth.png'];
  public SelectedIcon: string;
  public Items: MenuItem[] | undefined;
  public User: any;

  constructor(private _authService: AuthService, private _spotifyService: SpotifyService,) {
    super();
    this.SelectedIcon = '';
  }

  override ngOnInit(): void {
    this.SelectRandomIcon();
    this.Items = [
      {
        label: 'Spotify',
        items: [
          {
            label: 'Select device',
            icon: PrimeIcons.MOBILE,
            command: () => {
              this.ChangeActiveDevice();
            }
          }]
      },
      {
        label: 'Exit',
        items: [
          {
            label: 'Log out',
            icon: PrimeIcons.SIGN_OUT,
            command: () => {
              this.Logout();
            }
          },
        ]
      }];

    const subscription = this._authService.user$.subscribe((aUser: User | null) => {
      this.User = aUser;
    })

    this.Subscriptions.add(subscription);
  }

  public SelectRandomIcon(): void {
    const randomIndex = Math.floor(Math.random() * this.Icons.length);
    this.SelectedIcon = `/assets/profile/${this.Icons[randomIndex]}`;
  }

  public async Logout() {
    await this._authService.Logout();
  }

  public getFirstAlphabeticalCharacter(email: string): string | null {
    // Regular expression to match any alphabetical character
    const regex = /[a-zA-Z]/;

    for (let i = 0; i < email.length; i++) {
      if (regex.test(email[i])) {
        return email[i].toUpperCase();
      }
    }
    return "#";
  }

  public ChangeActiveDevice() {
    this._spotifyService.GetActiveDevices().subscribe(response => {
      if (response && response.devices) {
        this._spotifyService.Devices$ = response.devices;
        // this.Devices = response.devices;
        // this._spotifyService.ShouldSelectDevice$ = true;
      } else {
        this._spotifyService.Devices$ = [];
        // this.Devices = [];
      }
      this._spotifyService.ShouldSelectDevice$ = true;
    });
  }


}
