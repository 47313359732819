import {Component, inject} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastModule} from "primeng/toast";
import {MessageService} from "primeng/api";
import {ComponentBase} from "../../models/component-base";
import {NgIf} from "@angular/common";

@Component({
  selector: 'login',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ToastModule, NgIf],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [MessageService]
})
export class LoginComponent extends ComponentBase {

  public Email: string = "";
  public Password: string = "";
  public ErrorMessage: string = "";

  constructor(private _authService: AuthService, private _messageService: MessageService) { super(); }

  protected override OnInit(): void {
    const subscription = this._authService.GetMessage$.subscribe(message => {
      if (message && message != this.ErrorMessage) {
        this.ErrorMessage = message;
      }
    });
    this.Subscriptions.add(subscription);
  }

  public async Login() {
    await this._authService.LoginAndSetUser(this.Email, this.Password);
  }

  public ShowError(message: string) {
    this._messageService.add({ severity: 'error', summary: 'Error', detail: message});
  }

}
