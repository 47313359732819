<div class="container">
    <form (ngSubmit)="Register()">
      <h2 class="title-center">Register</h2>
      <p class="error">{{ErrorMessage}}</p>
      <div class="form-group">
        <label for="email">Email</label>
        <div class="box gradient-border">
          <input type="email" [(ngModel)]="Email" name="email" id="email" placeholder="Enter email">
        </div>
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <div class="box gradient-border">
          <input type="password" [(ngModel)]="Password" name="password" id="password" placeholder="Enter password">
        </div>
      </div>
      <div class="register-btn-container">
        <button class="btn-default" type="submit">Register</button>
        <p>Already have an account? <a href="/login">Login</a></p>
      </div>
    </form>
</div>

