import {inject, Injectable} from "@angular/core";
import {BehaviorSubject, from, Observable, of, Subscription, switchMap} from "rxjs";
import {Router, UrlTree} from "@angular/router";
import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  User,
  user
} from '@angular/fire/auth';
import {map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {child, Database, get, getDatabase, ref, set} from "@angular/fire/database";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFireDatabase} from "@angular/fire/compat/database";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _message = new BehaviorSubject<string>('');
  private _auth: Auth = inject(Auth);
  private tokenUrl: string = 'https://accounts.spotify.com/api/token';
  private db = inject(Database);

  public user$: Observable<User | null> = user(this._auth);
  public aUser: any;
  public userSubscription: Subscription;
  public ClientId: string = "08854cad33744f36a2900a7331646048";

  constructor(private _router: Router, private http: HttpClient) {
    this.userSubscription = this.user$.subscribe((aUser: User | null) => {
      this.aUser = aUser;
    })
  }

  public async LoginAndSetUser(email: string, password: string) {
    await signInWithEmailAndPassword(this._auth, email, password).then((user) => {
      if (user) {
        this._message.next('');
        this._router.navigate(['/']);
      }
    }).catch((e) => {
      this._message.next(this.firebaseAPIErrors(e.code));
    });
  }

  public async Logout() {
    await signOut(this._auth);
    await this._router.navigate(['/login']);
  }

  ngOnDestroy() {
    this.userSubscription.unsubscribe();
  }

  async RegisterAndSetUser(email: string, password: string) {
    await createUserWithEmailAndPassword(this._auth, email, password).then((user) => {
      if (user) {
        let uid = user.user.uid;
        set(ref(this.db, 'users/' + uid), {
          email: email,
          role: 'user'
        })
        this._message.next('');
        this._router.navigate(['/']);
      }
    }).catch((e) => {
      this._message.next(this.firebaseAPIErrors(e.code));
    });
  }

  public CheckLogin(): Observable<boolean | UrlTree> {
    return this.user$.pipe(
      map((aUser: User | null) => {
        if (aUser) {
          // If there's a user, the check is successful
          return true;
        } else {
          // If there's no user, redirect to the login page
          return this._router.createUrlTree(['/login']);
        }
      })
    );
  }

  public get GetMessage$(): Observable<string> {
    return this._message.asObservable();
  }

  public GenerateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  public async Sha256(plain: string): Promise<ArrayBuffer> {
    const encoder = new TextEncoder();
    const data = encoder.encode(plain);
    return window.crypto.subtle.digest('SHA-256', data);
  }

  public async Base64urlencode(a: ArrayBuffer): Promise<string> {
    let str = '';
    const bytes = new Uint8Array(a);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      str += String.fromCharCode(bytes[i]);
    }
    return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
  }

  public async GenerateCodeChallenge(codeVerifier: string): Promise<string> {
    const hashed = await this.Sha256(codeVerifier);
    return this.Base64urlencode(hashed);
  }

  public ExchangeCodeForToken(code: string): Observable<any> {
    const codeVerifier = localStorage.getItem('code_verifier');

    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');

    const isLocalhost = window.location.hostname === 'localhost';

    const body = new URLSearchParams({
      client_id: this.ClientId,
      grant_type: 'authorization_code',
      code: code,
      redirect_uri: isLocalhost ? 'http://localhost:4200/' : environment.redirectUri,
      code_verifier: codeVerifier || ''
    });
    return this.http.post(this.tokenUrl, body.toString(), { headers });
  }

  public getUserRole(uid: string): Observable<string | null> {
    const dbRef = ref(this.db);
    return from(get(child(dbRef, `users/${uid}/role`))).pipe(
      map(snapshot => snapshot.exists() ? snapshot.val() : null)
    );
  }

  private firebaseAPIErrors(code: string): string {
    switch (code) {
      case "auth/invalid-email":
        return "Invalid email address.";
      case "auth/missing-password":
        return "Missing password.";
      case "auth/invalid-credential":
        return "Invalid credential."
      case "auth/weak-password":
        return "Password must be at least 6 characters long.";
      case "auth/email-already-in-use":
        return "This email is already in use.";
      default:
        return "Something went wrong."
    }
  }

}
