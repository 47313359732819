<div class="container">
  <form (ngSubmit)="Login()">
    <h2 class="title-center">Login</h2>
    <p class="error">{{ErrorMessage}}</p>
    <div class="form-group">
      <label for="email">Email</label>
      <div class="box gradient-border">
        <input type="email" [(ngModel)]="Email" name="email" id="email" placeholder="Enter email">
      </div>
    </div>
    <div class="form-group">
      <label for="password">Password</label>
      <div class="box gradient-border">
        <input type="password" [(ngModel)]="Password" name="password" id="password" placeholder="Enter password">
      </div>
    </div>
    <div class="register-btn-container">
      <button class="btn-default" type="submit">Login</button>
      <p>Don't have an account? <a href="/register">Register</a></p>
    </div>
  </form>
</div>

