export const environment = {
  production: false,
  redirectUri: 'https://dev.guesic.com/',
  firebaseConfig: {
    apiKey: "AIzaSyBG5IBd-NwRFE48f_nRimj9UbBtGEbfgqQ",
    authDomain: "guesic-develop.firebaseapp.com",
    databaseURL: "https://guesic-develop-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "guesic-develop",
    storageBucket: "guesic-develop.appspot.com",
    messagingSenderId: "896223234861",
    appId: "1:896223234861:web:4c962d2cf1c84ce1aca1a4",
    measurementId: "G-WJ7CKQWSKV"
  }
};
